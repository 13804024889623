import { graphql, Link, useStaticQuery } from "gatsby"
import Logo from "./Logo"
import * as styles from "../styles/modules/Header.module.css"
import * as React from "react"

const Header = () => {
    const [subMenuOpen, setSubMenuOpen] = React.useState(false);
    const [opacity, setOpacity] = React.useState(0);
    const [visibility, setVisibility] = React.useState("hidden");
    let isHovered = false;
    const handleHover = newState => {
        if (newState) {
            isHovered = true;
        } else {
            isHovered = false;
        }
    }

    React.useEffect(() => {
        if (subMenuOpen) {
            setOpacity(1);
            subMenuOpen && setVisibility("visible")
        } else {
            setOpacity(0);
            setTimeout(() => {
                isHovered || setVisibility("hidden")
            }, (500));
        }
    }
        , [subMenuOpen])
    const subMenus = useStaticQuery(graphql`
    query subMenusQuery {
        wpMenu(name: { eq: "Menu Principal" }) {
          menuItems {
            nodes {
              childItems {
                nodes {
                  path
                  label
                }
              }
            }
          }
        }
      }
    `).wpMenu.menuItems.nodes.filter(item => item.childItems.nodes.length > 0)[0].childItems.nodes;

    return (

        <header className={styles.Header} style={{ zIndex: 1000, display: 'grid', gridTemplateRows: '1fr 0.3fr 2fr', margin: "5vh auto 3vh auto", position: "relative" }}>
            <TopPart toggleSubMenu={setSubMenuOpen} handleHover={handleHover} />
            <div style={{ margin: 0, backgroundColor: 'black', height: '1px', width: '100%' }} />
            <div>
                <Logo layout={"fixed"} width={45} height={45} />

                <ul className={styles.subMenu} style={{ opacity, visibility, transition: "opacity .3s ease-out" }} onMouseEnter={() => { handleHover(true); setSubMenuOpen(true) }} onMouseLeave={() => { handleHover(false); setSubMenuOpen(false) }}>
                    {subMenus.map((subMenu, index) => {
                        return (
                            <li key={`subMenu_${index}`}>
                                <Link className={styles.navLink} activeStyle={{ color: "#B5B5B5" }} to={subMenu.path} >{subMenu.label}</Link>

                            </li>
                        )
                    })}
                </ul>

            </div>
        </header>

    )
}

const TopPart = ({ toggleSubMenu, handleHover }) => {



    return (
        <nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: "relative" }}>
            <h1 style={{ fontFamily: "Poppins", fontSize: 17, fontWeight: "normal", letterSpacing: "1px" }}>MARIANNE ALLIOT</h1>
            <ul style={{ listStyle: 'none', display: 'flex', justifyContent: 'space-between', minWidth: '40%', padding: '0', margin: 0, alignItems: 'center' }}>
                <li><Link className={styles.navLink} activeStyle={{ color: "#B5B5B5" }} to={"/"}>home</Link></li>
                <li className={styles.subMenuParent} onMouseEnter={() => { handleHover(true); toggleSubMenu(true) }} onMouseLeave={() => { handleHover(false); handleHover(false) }} >
                    work

                </li>
                <li><Link className={styles.navLink} activeStyle={{ color: "#B5B5B5" }} to={"/about"}>about</Link></li>
                <li><Link className={styles.navLink} activeStyle={{ color: "#B5B5B5" }} to={"/contact"}>contact</Link></li>
            </ul>

        </nav>
    )
}


export default Header
