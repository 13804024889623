import * as React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import * as styles from "../styles/modules/pageWrapper.module.css";
import { Helmet } from "react-helmet"
import LogoMenuButton from "../components/LogoMenuButton"
import SlideMenu from "../components/SlideMenu"
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';



export const MyMenuContext = React.createContext({});
const PageWrapper = ({ children, altLogo, transparent }) => {
    const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
    const toggleSideMenu = () => setSideMenuOpen(!sideMenuOpen);

    return (
        <MyMenuContext.Provider value={{isOpen: sideMenuOpen, toggleMenu: toggleSideMenu}}>
            <ToastContainer/>
            <SlideMenu />
            <LogoMenuButton altLogo={altLogo} />

            <main className={styles.pageWrapper}>
                <Helmet title="Marianne Alliot">
                    <link rel="stylesheet" href="https://use.typekit.net/lhy3kfm.css" />
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
                </Helmet>
                <Header />
                {children}
                <Footer transparent={transparent}></Footer>
            </main>
        </MyMenuContext.Provider>
    )
}



export default PageWrapper

