import { Link, useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import * as styles from "../styles/modules/Header.module.css"
import {MyMenuContext} from "./PageWrapper"

function Menu() {
  const {isOpen} = React.useContext(MyMenuContext);
  const [subOpen, setSubOpen] = React.useState(false);

  const subMenus = useStaticQuery(graphql`
    query sideSubMenusQuery {
        wpMenu(name: { eq: "Menu Principal" }) {
          menuItems {
            nodes {
              childItems {
                nodes {
                  path
                  label
                }
              }
            }
          }
        }
      }
    `).wpMenu.menuItems.nodes.filter(item => item.childItems.nodes.length > 0)[0].childItems.nodes;
  
  return (
    <Wrapper isOpen={isOpen}>
        <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: "10vh", padding: '0', }}>
                <li className={styles.sideNavLink}><Link className={styles.navLink} activeStyle={{ color: "#E3D400" }} to={"/"}>home</Link></li>
                <li className={styles.sideNavLink} style={{marginBottom: subOpen ? 10 : 20, cursor: "pointer"}} onClick={() => setSubOpen(!subOpen)} >
                    work
                    {subOpen && (
                    <ul className={styles.sideSubMenu}>
                    {subMenus.map((subMenu, index) => {
                        return (
                            <li key={`subMenu_${index}`}>
                                <Link className={styles.navLink} activeStyle={{ color: "#E3D400" }} to={subMenu.path} >{subMenu.label}</Link>

                            </li>
                        )
                    })}
                    </ul>
                    )}
                </li>
                <li className={styles.sideNavLink}><Link className={styles.navLink} activeStyle={{ color: "#E3D400" }} to={"/about"}>about</Link></li>
                <li className={styles.sideNavLink}><Link className={styles.navLink} activeStyle={{ color: "#E3D400" }} to={"/contact"}>contact</Link></li>
            </ul>
    </Wrapper>
  );
}

const Wrapper = ({isOpen, children}) => {
    console.log(isOpen);
    return (
        <div className={styles.sideMenuWrapper} style={{zIndex: 1000, display: isOpen ? "block" : "none",position: "fixed", left:0, top: 0, bottom: 0, width: "60vw", height: "100vh", backgroundColor: "rgba(255,255,255,0.8)", boxSizing: "border-box", padding: "100px 0 0 30px"}}>
            {children}
        </div>
    )
}

export default Menu;
