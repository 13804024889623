import * as React from "react"
import {FaFacebookF} from "react-icons/fa"
import {BsInstagram} from "react-icons/bs"
import * as styles from "../styles/modules/Footer.module.css"

const Footer = ({transparent}) => {
    
    return (
        <footer id="Footer" transparent={transparent ? transparent.toString() : "false"} className={styles.Footer} >
            <div style={{display: 'flex', width: "5ch", justifyContent: 'space-between'}}>
                <a className={styles.footerLink} href="https://www.instagram.com/mariannealliot/" target="_blank" rel="noopener noreferrer"><BsInstagram /></a>
                <a className={styles.footerLink} href="https://www.facebook.com/marianne.alliot" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            </div>
            <p>All rights reserved © mariannealliot 2021</p>
        </footer>
    )
}

export default Footer
